import doodle from 'assets/images/not-found.svg'
import { Head, Main } from 'components'
import { Link, PageProps } from "gatsby"
import React from "react"
import styled from 'styled-components'

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <>
      <Head title="Not Found" />
      <Main>
        <StyledSection id='page-not-found'>
          <div className="wrapper">
            <img src={doodle} alt="404" className="img" />
            <h1 className='title'>Ooops! Something isn't right</h1>
            <p>You just opened a page that doesn't exist.</p>
            <Link to='/' className="btn">Back to HomePage</Link>
          </div>
        </StyledSection>
      </Main>
    </>
  )
}

const StyledSection = styled.section`
  height: 100vh;
  padding: 5rem 1rem;
  background: var(--white);

  .wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    max-width: 55rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .img {
    height: 15rem;
    object-fit: contain;
  }

  .title {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 720px) {
      padding: 2.5rem 1.25rem;
      .wrapper {
        text-align: center;
      }
  }
`;

export default NotFoundPage;
